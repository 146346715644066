'use client'
import {useCallback, useEffect, useRef} from 'react'
import {Analytics} from '~/utils/Analytics'

const eventNameDataTag = 'data-analytics-event-name'

const dataTags = [
  eventNameDataTag,
  'data-page',
  'data-page-variant',
  'data-theme',
  'data-section',
  'data-button-variant',
] as const

export function collectEventData(rootElement: HTMLElement | null) {
  if (!rootElement) return null

  const analyticsEventName = rootElement.getAttribute(eventNameDataTag)
  if (!analyticsEventName) {
    return null
  }
  const analyticsData: Partial<Record<(typeof dataTags)[number], string>> & {
    'data-analytics-event-name': string
  } = {
    [eventNameDataTag]: analyticsEventName,
  }

  let currentElement: HTMLElement | null = rootElement

  while (currentElement) {
    for (const dataTag of dataTags) {
      if (currentElement.hasAttribute(dataTag) && !analyticsData[dataTag]) {
        analyticsData[dataTag] = currentElement.getAttribute(dataTag) || ''
      }
    }

    currentElement = currentElement.parentElement
  }

  return analyticsData
}

function handleClick(event: MouseEvent) {
  const target = event.currentTarget as HTMLElement // NOTE: this is `currentTarget` not `target` to ensure we are using the element that the listener was attached to

  const ignoreAnalyticsEvent = target.getAttribute(
    'data-analytics-disable-click-listener'
  )
  if (ignoreAnalyticsEvent === 'true') {
    return null
  }

  const eventData = collectEventData(target)
  if (!eventData) {
    return
  }

  const {
    'data-analytics-event-name': analyticsEventName,
    'data-page': page,
    'data-page-variant': pageVariant,
    'data-theme': theme,
    'data-section': section,
    'data-button-variant': buttonVariant,
  } = eventData

  Analytics.trackClick({
    type: analyticsEventName,
    label: target.textContent ?? undefined,
    buttonColor: buttonVariant,
    linkedPath: target.getAttribute('href') ?? undefined,
    location: section,
    customProperties: {
      page,
      pageVariant,
      theme,
      section,
    },
  })
}

export function AnalyticsClickListener() {
  const attachedElementsRef = useRef<Set<HTMLElement>>(new Set())

  const attachEventListeners = useCallback(() => {
    const elements = document.querySelectorAll<HTMLElement>(`[${eventNameDataTag}]`)
    elements.forEach(element => {
      if (!attachedElementsRef.current.has(element)) {
        element.addEventListener('click', handleClick)
        attachedElementsRef.current.add(element)
      }
    })
  }, [])

  useEffect(() => {
    attachEventListeners()

    const observer = new MutationObserver(() => {
      attachEventListeners()
    })

    observer.observe(document.body, {childList: true, subtree: true})

    const removeEventListeners = () => {
      attachedElementsRef.current.forEach(element => {
        element.removeEventListener('click', handleClick)
      })
      attachedElementsRef.current.clear()
      observer.disconnect()
    }

    return () => {
      removeEventListeners()
    }
  }, [attachEventListeners])

  return null
}
