'use client'

import * as ContextMenu from '@radix-ui/react-context-menu'
import {forwardRef} from 'react'
import {tv} from 'tailwind-variants'
import {LinkWrapper} from '~/design-system/foundations'
import {HgIcon} from '~/design-system/hg/components'
import {cn} from '~/design-system/utils'
import {externalLinks} from '~/routing/external-links'
import {internalLinks} from '~/routing/internal-links'
import {download} from '~/utils/fileDownloads'
import MercuryLogoWordmark from '../_images/mercury-logo-icon-and-wordmark.svg'

const LOGO_DOWNLOAD_URL_PREFIX = '/icons/LogoDownloads'

const menuItem = tv({
  base: 'relative flex cursor-pointer select-none items-center overflow-hidden rounded fill-icon-subdued px-s3 py-s2 text-text-subdued transition-colors duration-300 ease-out arcadia-ui-1 data-[highlighted]:bg-surface-elevated-hover',
})

type WordmarkProps = {
  className?: string
  handleDownloadAnalyticsEvent?: (analyticsEventName: string) => void
}

const HgNavigationWordmark = forwardRef<HTMLAnchorElement, WordmarkProps>(
  ({className, handleDownloadAnalyticsEvent}, ref) => {
    return (
      <div className={cn('inline-flex items-center', className)}>
        <ContextMenu.Root>
          <ContextMenu.Trigger className="rounded outline-2 outline-offset-4 outline-border-focus has-[a:focus-visible]:outline">
            <LinkWrapper
              data-analytics-event-name="homepage"
              className="w-full max-w-fit items-center rounded fill-icon-default text-text-default outline-none hover:fill-icon-subdued hover:text-text-subdued hover:transition-colors hover:duration-300 hover:ease-out active:fill-icon-emphasized active:text-text-emphasized"
              href={internalLinks.home}
              ref={ref}
            >
              <MercuryLogoWordmark title="Mercury logo" />
            </LinkWrapper>
          </ContextMenu.Trigger>
          <ContextMenu.Portal>
            <ContextMenu.Content className="w-fit-content z-20 min-w-[220px] rounded-lg bg-surface-elevated p-s2 shadow-[0px_0px_3px_0px_rgba(28,28,35,0.09),0px_6px_10px_0px_rgba(28,28,35,0.04),0px_10px_16px_0px_rgba(28,28,35,0.02)]">
              <ContextMenu.Item
                className={menuItem()}
                onSelect={() => {
                  download(
                    'mercury-logo-icon.svg',
                    `${LOGO_DOWNLOAD_URL_PREFIX}/mercury-logo-icon.svg`
                  )
                  handleDownloadAnalyticsEvent?.('homepageIconDownload')
                }}
              >
                <HgIcon iconType="download" size="regular" className="me-s3" />
                Download icon as .svg
              </ContextMenu.Item>
              <ContextMenu.Item
                className={menuItem()}
                onSelect={() => {
                  download(
                    'mercury-logo-icon-and-wordmark.svg',
                    `${LOGO_DOWNLOAD_URL_PREFIX}/mercury-logo-icon-and-wordmark.svg`
                  )
                  handleDownloadAnalyticsEvent?.('homepageFullLogoDownload')
                }}
              >
                <HgIcon iconType="download" size="regular" className="me-s3" />
                Download full logo as .svg
              </ContextMenu.Item>
              <ContextMenu.Separator className="m-s2 h-[1px] bg-border-subdued" />
              <ContextMenu.Item
                className={menuItem()}
                onSelect={() => {
                  download(
                    'mercury-logos.zip',
                    externalLinks.logoDownloads.mercuryBrandKitZip
                  )
                  handleDownloadAnalyticsEvent?.('homepageLogoZipDownload')
                }}
              >
                <HgIcon iconType="file-zipper" size="regular" className="me-s3" />
                Download brand kit as .zip
              </ContextMenu.Item>
            </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>
      </div>
    )
  }
)

HgNavigationWordmark.displayName = 'HgNavigationWordmark'

export default HgNavigationWordmark
