'use client'

import HgNavbar from '~/design-system/hg/modules/HgNavbar'
import {type HgNavbarProps} from '~/design-system/hg/modules/HgNavbar/types'
import {Analytics} from '~/utils/Analytics'

export const NavbarClient = (
  props: Omit<HgNavbarProps, 'handleDownloadAnalyticsEvent'>
) => {
  return (
    <HgNavbar
      handleDownloadAnalyticsEvent={analyticsEventName => {
        Analytics.track('download', {downloadName: analyticsEventName})
      }}
      {...props}
    />
  )
}
